<template>
  <div>
    <div>
      <div v-if="carregando == true" class="carregando">
        <!--img
          width="100"
          src="https://bboneapp.s3.amazonaws.com/evo-white.png"
        /-->
        <br />
        <br />
        <br />Carregando.. Aguarde
      </div>
      <Topo :decoded="decoded" @OpenMenuLateral="menuOpen = true"></Topo>
      <div class="container p-3 mb-5">
        <b-row>
          <b-col cols="1">
            <router-link
              v-if="exibeDetalhe == false"
              class="link-black"
              tag="a"
              :to="'/dashboard'"
            >
              <i class="fa fa-chevron-left text-success"></i>
            </router-link>
          </b-col>
          <b-col>
            <h5 class="m-4 text-center">
              Propostas:
              <span class="text-secondary">Em Aberto</span>
            </h5>
          </b-col>
        </b-row>
        <b-row v-if="exibeDetalhe == false">
          <b-col
            ><ListCotacoes
              :cotacoes="cotacoesShow"
              @DetalheCotacao="DetalheCotacao"
            ></ListCotacoes
          ></b-col>
        </b-row>
        <b-row v-else>
          <b-col>
            <DetalheCotacao
              @FechaDetalhe="exibeDetalhe = false"
              :itemDetalhe="itemDetalhe"
              :selecionouCotacao="selecionouCotacao"
            ></DetalheCotacao>
          </b-col>
        </b-row>
      </div>
      <MenuRodape
        @OpenMenuLateral="menuOpen = true"
        :decoded="decoded"
      ></MenuRodape>
    </div>
    <MenuLateral
      :decoded="decoded"
      :openMenuLateral="menuOpen"
      @closeMenuLateral="menuOpen = false"
    ></MenuLateral>
  </div>
</template>
<!-- eslint-disable -->
<script>
import jwt from 'jsonwebtoken';
import Topo from './Topo';
import MenuRodape from './MenuRodape';
import ConteudoDash from './ConteudoDash';
import MenuLateral from './MenuLateral';
import service from '../services';
import moment from 'moment';

import ListCotacoes from './ListCotacoes';

import DetalheCotacao from './DetalheProposta';

export default {
  name: 'DashBoard',
  props: {
    msg: String,
  },
  data() {
    return {
      decoded: {},
      menuOpen: false,
      boletos: [],
      carregando: false,
      selecionouCotacao: false,
      exibeDetalhe: false,
      itemDetalhe: null,
      regulamento: null,
      cotacoesShow: [],
      cotacoes: [],
      cotacoesShow: [],
      cotacoesNetwork: [],
      cotacoesSite: [],
      cotacoesIndicacao: [],
      exibeDetalhe: false,
      filtro: {
        status: [{ id: 1 }, { id: 3 }, { id: 5 }, { id: 6 }],
      },
    };
  },
  watch: {},
  components: {
    Topo,
    ConteudoDash,
    MenuRodape,
    MenuLateral,
    ListCotacoes,
    DetalheCotacao,
  },
  methods: {
    DetalheCotacao(item) {
      this.exibeDetalhe = true;
      console.log(item);
      this.itemDetalhe = item;
      this.selecionouCotacao = true;
    },
    copiarText(linha) {
      //O texto que será copiado
      const texto = linha;
      //Cria um elemento input (pode ser um textarea)
      let inputTest = document.createElement('input');
      inputTest.value = texto;
      //Anexa o elemento ao body
      document.body.appendChild(inputTest);
      //seleciona todo o texto do elemento
      inputTest.select();
      //executa o comando copy
      //aqui é feito o ato de copiar para a area de trabalho com base na seleção
      document.execCommand('copy');
      //remove o elemento
      document.body.removeChild(inputTest);
      this.$bvToast.toast(
        `Linha digitável copiado para a área de Transfêrencia`,
        {
          title: 'Atenção!',
          solid: true,
          variant: 'success',
          toaster: 'b-toaster-top-full',
          appendToast: false,
          autoHideDelay: 2500,
        },
      );
    },
    validaVencimento(data) {
      if (moment().diff(data, 'days') <= 5) return true;
      else return false;
    },
    retornaClasseVencimento(data, situacao) {
      if (situacao == 'BAIXADO') return 'warning';
      else if (moment().diff(data, 'days') > 1) return 'danger';
    },
    formataId(id) {
      return id.toString().padStart(6, '0');
    },
    formataData(data) {
      return moment(data).format('DD/MM/YYYY');
    },
    formataDesde(data) {
      return moment(data).format('MM/YYYY');
    },
    formataAno(data) {
      return moment(data).format('YYYY');
    },
    openDecoded() {
      const token = localStorage.getItem('user-token');
      if (token) {
        this.decoded = jwt.decode(token);
        console.log(this.decoded);
      }
    },
    buscaCotacoes() {
      return new Promise((resolve, reject) => {
        this.openDecoded();
        this.cotacoesShow = [];
        this.cotacoesNetwork = [];
        this.cotacoesSite = [];
        this.carregando = true;
        //this.mockSwipeList[0].push(this.template2);
        var form = { ...this.decoded };
        form.status = this.filtro.status;
        if (this.decoded.tipo == 'Consultor') {
          form.consultor = [{ id: this.decoded.id_usuario }];
        } else {
          form.idProspect = [{ id: this.decoded.id_prospect }];
          form.status.push({ id: 4 });
        }
        form.pagina = 1;
        form.qtdPorPagina = 25;
        delete form.placa;

        service.post('Cotacao', 'listarCotacoesFiltro', form).then((res) => {
          console.log(res.data);
          res.data.cotacoes.forEach((element) => {
            element.exibeDetalhe = false;
            if (this.decoded.tipo == 'Consultor') {
              this.cotacoes.push(element);
              if (element.origem == 'Network')
                this.cotacoesNetwork.push(element);
              if (element.origem == 'Site' || element.origem == 'B2C')
                this.cotacoesSite.push(element);
              if (element.origem == 'Indicação')
                this.cotacoesIndicacao.push(element);
            } else {
              if (element.nomeStatus != 'Recusada') {
                this.cotacoes.push(element);
                if (element.origem == 'Network')
                  this.cotacoesNetwork.push(element);
                if (element.origem == 'Site' || element.origem == 'B2C')
                  this.cotacoesSite.push(element);
                if (element.origem == 'Indicação')
                  this.cotacoesIndicacao.push(element);
              }
            }
          });
          this.carregando = false;
          var cotacoesAux = [];

          /*if (this.cotacoesSite.length > 0)
            this.cotacoesShow = this.cotacoesSite;

          if (this.cotacoesIndicacao.length > 0)
            this.cotacoesShow = this.cotacoesIndicacao;
          if (this.cotacoesNetwork.length > 0)
            this.cotacoesShow = this.cotacoesNetwork;
          */
          if (this.decoded.tipo == 'Consultor') {
            this.cotacoesShow = this.cotacoesNetwork;
          } else {
            this.cotacoesShow = [
              ...this.cotacoesNetwork,
              ...this.cotacoesIndicacao,
              ...this.cotacoesSite,
            ];
          }
          resolve();
        });
      });
    },
  },
  mounted() {
    this.buscaCotacoes();
  },
};
</script>

<style lang="scss" scoped>
body {
  overflow-x: hidden;
  background: none !important;
}
h5 {
  text-transform: uppercase;
  font-family: 'robotobold';
}
</style>
